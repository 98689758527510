.wrapper {
  margin: 0 auto;
  position: relative;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .wrapper {
    overflow-x: hidden;
  }
}
